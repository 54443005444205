<template>
    <v-row justify="center" class="py-5" align="center" style="height: 100%">
        <v-col cols="12" sm="6">
            <BrandImage :alias="brand.alias" intent="logotype" mode="light" etag="0" :height="56" :max-height="56" contain style="margin-left: auto; margin-right: auto;" class="mb-6"/>
            <BrandCard>
                <v-card-text>
                    <template v-if="interactionError">
                        <p class="text-h5 font-weight-light red--text">We cannot process this request.</p>
                    </template>
                    <template v-if="errorUnauthorizedInteraction">
                        <p class="text-h5 font-weight-light red--text">Unauthorized</p>
                        <p class="text-h5 font-weight-light">Did you start in another browser? Try opening the link in that browser, or continue in this one.</p>
                        <p class="text-body-1 font-weight-light text-center">
                            <!-- <router-link :to="{ name: 'brand', params: { alias: this.$route.params.alias } }">Continue</router-link> -->
                            <v-btn :to="{ name: 'brand', params: { alias: this.$route.params.alias }, query: { email: this.$route.query.email } }" :style="primaryButtonStyle">Continue</v-btn>
                        </p>
                    </template>
                </v-card-text>
            </BrandCard>
        </v-col>
    </v-row>
</template>
<style lang="css">
</style>
<script>
import { mapState, mapGetters } from 'vuex';
import BrandCard from '@/components/BrandCard.vue';
import BrandImage from '@/components/BrandImage.vue';

export default {
    components: {
        BrandCard,
        BrandImage,
    },
    data: () => ({
        isViewReady: false,
        interactionId: null,
        // loading: true,
        interactionError: false,
        errorUnauthorizedInteraction: false,
    }),
    computed: {
        ...mapState({
            // isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
            brand: (state) => state.brand,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            isAuthenticatedReady: 'isAuthenticatedReady',
            brandName: 'brandName',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            accentColor: 'accentColor',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            cardTitleBarStyle: 'cardTitleBarStyle',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
        // isAuthenticated() {
        //     return this.session.isAuthenticated;
        // },
    },
    methods: {
        async init() {
            console.log('interaction.vue: init; isAuthenticated: %o', this.isAuthenticated);
            if (this.$route.query.token) {
                await this.resumeInteraction(this.$route.query.token);
            }
            if (this.interactionId) {
                await this.loadInteraction();
            }
            this.isViewReady = true;
        },
        async resumeInteraction(token) {
            try {
                this.errorUnauthorizedInteraction = false;
                this.interactionError = false;
                this.$store.commit('loading', { resumeInteraction: true });
                const response = await this.$client.main().interaction.resume(token);
                console.log(`resumeInteraction response: ${JSON.stringify(response)}`);
                const { interaction_id: interactionId, next_route: nextRoute, error } = response;
                if (error) {
                    this.interactionError = true;
                } else if (nextRoute) {
                    // the server may have edited the session when processing the token,
                    // so refresh before we redirect
                    await this.$store.dispatch('refresh');
                    console.log(`resumeInteraction nextRoute: ${JSON.stringify(nextRoute)}`);
                    this.$router.replace(nextRoute);
                } else {
                    this.interactionId = interactionId;
                }
            } catch (err) {
                console.error('failed to activate token', err);
                if (err.response?.status === 401) {
                    this.errorUnauthorizedInteraction = true;
                }
            } finally {
                this.$store.commit('loading', { resumeInteraction: false });
            }
        },
        async loadInteraction() {
            try {
                this.$store.commit('loading', { loadInteraction: true });
                const response = await this.$client.main().interaction.get(this.interactionId);
                console.log(`loadInteraction response: ${JSON.stringify(response)}`);
                const { type, next, state } = response;
                console.log(`interaction.vue: loadInteraction: interaction type: ${type} next: ${next} state ${JSON.stringify(state)}`);
                // switch (type) {
                // case 'create_account':
                //     this.createAccount({ type, next, state }, prevInteraction);
                //     break;
                // default:
                //     console.error('interaction.vue: unknown interaction type: %s', type);
                //     this.interactionError = true;
                //     break;
                // }
            } catch (err) {
                console.error('failed to activate token', err);
            } finally {
                this.$store.commit('loading', { loadInteraction: false });
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>
